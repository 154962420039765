import React from 'react';
import { Link } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';

const NotFoundPage = props => (
	<Layout pageProps={props}>
		<SEO title="404: Not found" />
		<h1>404 Page Not Found</h1>
		<Link to="/">Return Home</Link>
	</Layout>
);

export default NotFoundPage;
